.info-cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 20px;
    background-color: #f8f9fa;
  
    .card {
      background: #fff;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      padding: 20px;
      text-align: center;
      width: 300px;
      margin: 10px;
      transition: transform 0.3s, box-shadow 0.3s;
  
      &:hover {
        transform: translateY(-5px);
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
      }
  
      .card-icon {
        font-size: 40px;
        color: #1c5306;
        margin-bottom: 15px;
      }
  
      .card-title {
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 10px;
        color: #333;
      }
  
      .card-caption {
        font-size: 16px;
        color: #666;
      }
    }
  }
  
  @media (max-width: 768px) {
    .info-cards-container {
      flex-direction: column;
      align-items: center;
  
      .card {
        width: 90%;
        margin: 10px 0;
      }
    }
  }
  
  @media (max-width: 480px) {
    .info-cards-container {
      .card {
        padding: 15px;
  
        .card-title {
          font-size: 20px;
        }
  
        .card-caption {
          font-size: 14px;
        }
  
        .card-icon {
          font-size: 35px;
        }
      }
    }
  }
  