.pagination {
  display: flex;
  list-style: none;
  padding: 0;

  li {
    margin: 0 5px;

    a {
      text-decoration: none;
      padding: 5px 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
      color: #007bff;
      &.active {
        background-color: #007bff;
        color: white;
      }
      &:hover {
        background-color: #0056b3;
        color: white;
      }
    }
  }
}
