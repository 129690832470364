.loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background-color: #f8f9fa; // Light background color
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999; // Ensures it's on top of all other elements
    flex-direction: column;
    
    .spinner-logo {
      width: 80px;
      height: 80px;
      margin-bottom: 20px;
    }
  
    .spinner {
      width: 80px;
      height: 80px;
      border: 4px solid rgba(0, 0, 0, 0.1);
      border-top: 4px solid #1c5306; // Primary color
      border-radius: 50%;
      animation: spin 2.5s linear infinite;
    }
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  