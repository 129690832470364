/* PasswordResetForm.scss */
.password-reset-form {
    max-width: 400px;
    margin: 0 auto;
    padding: 1rem;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  
    h2 {
      text-align: center;
      margin-bottom: 1rem;
      font-size: 1.5rem;
    }
  
    .message {
      text-align: center;
      margin-bottom: 1rem;
      color: red;
    }
  
    .form-group {
      margin-bottom: 1rem;
  
      label {
        display: block;
        margin-bottom: 0.5rem;
        font-weight: bold;
      }
  
      select,
      input {
        width: 100%;
        padding: 0.5rem;
        border: 1px solid #ccc;
        border-radius: 4px;
      }
    }
  
    button {
      width: 100%;
      padding: 0.75rem;
      background-color: #007bff;
      color: #fff;
      border: none;
      border-radius: 4px;
      font-size: 1rem;
      cursor: pointer;
      transition: background-color 0.3s;
  
      &:hover {
        background-color: #0056b3;
      }
    }
  }
  