.header {
  display: flex;
  justify-content: space-between;
  align-items: center; // Align items centrally for better appearance
  padding: 10px 20px;
  background-color: #1c5306;
  color: #fff;
  width: 100%; // Ensure the header takes up the full width
  
  .logo-container {
    .logo {
      height: 100px;
      border-radius: 15px;
    }
  }

  .header-text {
    h2 {
      font-size: 50px;
      margin: 0;
    }
  }

  .buttons-container {
    display: flex;
    gap: 10px;

    .header-button {
      background-color: #fff;
      color: #333;
      border: none;
      padding: 10px 20px;
      border-radius: 5px;
      cursor: pointer;
      font-size: 16px;

      &:hover {
        background-color: #ccc;
      }
    }
  }

  // Medium screens (tablets)
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    padding: 10px; // Adjust padding for better space management

    .logo-container {
      .logo {
        height: 50px; // Smaller logo for mobile view
      }
    }

    .header-text {
      h2 {
        font-size: 28px; // Smaller font size for mobile view
      }
    }

    .buttons-container {
      flex-direction: column;
      gap: 10px;
      width: 100%; // Ensure buttons take up full width

      .header-button {
        width: 100%; // Full width for mobile buttons
        padding: 10px;
        font-size: 16px;
      }
    }
  }

  // Small screens (mobile)
  @media (max-width: 480px) {
    padding: 5px 10px;

    .logo-container {
      .logo {
        height: 40px; // Even smaller logo for mobile
      }
    }

    .header-text {
      h2 {
        font-size: 22px; // Further reduce font size
      }
    }

    .buttons-container {
      .header-button {
        padding: 8px;
        font-size: 14px; // Smaller font for buttons
      }
    }
  }
}
