.footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
  background-color: #1c5306;
  color: #fff;
  width: 100%; // Ensure the footer takes the full width

  .footer-about, .footer-contact {
    flex: 1;
    margin: 0 20px;
  }

  .footer-logo {
    height: 100px;
    margin-bottom: 10px;
    border-radius: 10px;
  }

  .footer-about p {
    margin: 10px 0;
  }

  .footer-contact h3 {
    margin-bottom: 10px;
  }

  .footer-contact p {
    margin: 5px 0;
  }

  // Medium screens (tablets)
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 15px;

    .footer-about, .footer-contact {
      margin: 10px 0;
      width: 100%; // Make sure these sections take the full width on mobile
    }

    .footer-logo {
      height: 50px; // Smaller logo for tablet view
    }
  }

  // Small screens (mobile)
  @media (max-width: 480px) {
    padding: 10px; // Adjust padding for smaller screens

    .footer-about, .footer-contact {
      width: 100%; // Full-width for both sections
      margin: 5px 0;
    }

    .footer-logo {
      height: 40px; // Smaller logo for mobile
      margin-bottom: 15px;
    }

    .footer-about p, .footer-contact p {
      margin: 8px 0; // Slightly more spacing for mobile
    }

    .footer-contact h3 {
      margin-bottom: 8px;
    }
  }
}
