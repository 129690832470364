.card-container {
    width: 300px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  
    .card-header {
      background-color: #1c5306;
      color: #fff;
      padding: 1rem;
      text-align: center;
  
      h3 {
        margin: 0;
      }
  
      p {
        font-size: 0.8rem;
      }
    }
  
    .card-body {
      padding: 1rem;
  
      .card-row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.5rem;
  
        p {
          margin: 0;
        }
      }
    }
  }