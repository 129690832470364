.testimonial-heading{
    padding-bottom: 40px;
    padding-top: 40px;
    margin: 0 auto;
    text-align: center;
    h1{
        color: #1c5306;
        font-family: Tahoma, sans-serif;
        padding: 20px;
    }
    p{
        color: #666;
        font-size: 20px;
        padding: 20px;
    }
}





.testimonial-slider {
    width: 80%;
    margin: 0 auto;
    padding: 40px 0;
    margin-bottom: 40px;
  
    .testimonial {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      background: #fff;
      border-radius: 8px;
      padding: 20px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  
      .testimonial-image {
        border-radius: 50%;
        width: 100px;
        height: 100px;
        margin-bottom: 20px;
      }
  
      .testimonial-text {
        font-size: 18px;
        color: #666;
        margin-bottom: 15px;
      }
  
      .testimonial-name {
        font-size: 20px;
        font-weight: 700;
        color: #333;
      }
    }
  }
  
  @media (max-width: 768px) {
    .testimonial-slider {
      width: 100%;
      padding: 20px 0;
  
      .testimonial {
        padding: 15px;
  
        .testimonial-text {
          font-size: 16px;
        }
  
        .testimonial-name {
          font-size: 18px;
        }
      }
    }
  }
  
  @media (max-width: 480px) {
    .testimonial-slider {
      .testimonial {
        padding: 10px;
  
        .testimonial-text {
          font-size: 14px;
        }
  
        .testimonial-name {
          font-size: 16px;
        }
      }
    }
  }
  