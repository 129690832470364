.beneficiary-container {
  padding: 20px;
}

.beneficiary-container h2 {
  text-align: center;
  margin-bottom: 20px;
}

.card-container {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 10px;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-body {
  margin-top: 10px;
}

.card-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}
