.customer-info-table-container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  
    .title {
      font-size: 24px;
      margin-bottom: 20px;
      text-align: center;
    }
  
    .customer-info-table {
      width: 100%;
      border-collapse: collapse;
  
      th, td {
        padding: 12px 15px;
        border: 1px solid #ddd;
        text-align: left;
      }
  
      th {
        background-color: #007bff;
        color: white;
      }
  
      tbody tr:nth-child(even) {
        background-color: #f2f2f2;
      }
  
      .update-button, .delete-button {
        padding: 5px 10px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s;
      }
  
      .update-button {
        background-color: #28a745;
        color: white;
        margin-right: 5px;
  
        &:hover {
          background-color: #218838;
        }
      }
  
      .delete-button {
        background-color: #dc3545;
        color: white;
  
        &:hover {
          background-color: #c82333;
        }
      }
    }
  }
  
  @media (max-width: 768px) {
    .customer-info-table-container {
      padding: 10px;
  
      .title {
        font-size: 20px;
      }
  
      .customer-info-table {
        th, td {
          padding: 8px 10px;
        }
  
        .update-button, .delete-button {
          padding: 4px 8px;
          font-size: 14px;
        }
      }
    }
  }
  