.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 500px;
}

.overlay-content h2 {
  margin-top: 0;
}

.overlay-content form {
  display: flex;
  flex-direction: column;
}

.overlay-content label {
  margin-bottom: 10px;
}

.overlay-content input,
.overlay-content select {
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.overlay-content button {
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.overlay-content button[type="submit"] {
  background: #4caf50;
  color: white;
}

.overlay-content button[type="button"] {
  background: #f44336;
  color: white;
  margin-top: 10px;
}
