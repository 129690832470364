.success-banner-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .success-banner {
    background-color: white;
    padding: 40px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    position: relative;
  }
  
  .checkmark {
    display: inline-block;
    margin-bottom: 20px;
  }
  
  .checkmark-svg {
    width: 100px;
    height: 100px;
  }
  
  .checkmark-circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #4caf50;
    fill: none;
    animation: stroke 0.6s ease-in-out forwards;
  }
  
  .checkmark-check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    stroke-width: 2;
    stroke: #4caf50;
    animation: stroke 0.3s 0.6s ease-in-out forwards;
  }
  
  @keyframes stroke {
    100% {
      stroke-dashoffset: 0;
    }
  }
  
  h1 {
    margin-top: 0;
    font-size: 24px;
    color: #4caf50;
  }
  
  .close-button {
    background-color: #f44336;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 20px;
    transition: background-color 0.3s ease;
  
    &:hover {
      background-color: #d32f2f;
    }
  }
  