$table-bg: #f5f5f5;
$table-border: 1px solid #ddd;
$table-header-bg: #1c5306;
$table-header-color: #fff;
$table-header-border: 1px solid #333;
$table-row-hover-bg: #eee;

table {
  width: 100%;
  border-collapse: collapse;
  background-color: $table-bg;

  th,
  td {
    padding: 12px 15px;
    border: $table-border;
    text-align: left;
  }

  th {
    background-color: $table-header-bg;
    color: $table-header-color;
    border-bottom: $table-header-border;
  }

  tr:hover {
    background-color: $table-row-hover-bg;
  }
}

.pagination {
    display: flex;
    justify-content: center;
    gap: 8px;
  
    button {
        margin-top: 10px;
      padding: 8px 16px;
      font-size: 16px;
      border: 1px solid #ccc;
      background-color: #f8f8f8;
      cursor: pointer;
  
      &.active,
      &:hover {
        background-color: #ddd;
      }
    }
  }