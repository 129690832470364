.form-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .form-title {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .customer-bank-info-form {
    display: flex;
    flex-direction: column;
  
    .form-group {
      margin-bottom: 15px;
  
      label {
        display: block;
        margin-bottom: 5px;
        font-weight: bold;
      }
  
      input {
        width: 100%;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 4px;
      }
    }
  
    .submit-button {
      padding: 10px 15px;
      border: none;
      border-radius: 4px;
      background-color: #007bff;
      color: white;
      cursor: pointer;
      transition: background-color 0.3s;
  
      &:hover {
        background-color: #0056b3;
      }
    }
  }
  
  @media (max-width: 768px) {
    .form-container {
      padding: 10px;
    }
  
    .form-title {
      font-size: 20px;
    }
  
    .form-group {
      label {
        font-size: 14px;
      }
  
      input {
        padding: 8px;
        font-size: 14px;
      }
    }
  
    .submit-button {
      padding: 8px 10px;
      font-size: 14px;
    }
  }
  