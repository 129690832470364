.app {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  
    h1 {
      text-align: center;
      margin-bottom: 20px;
    }
  }
  