.fixed-deposit-form {
    max-width: 400px;
    margin: 0 auto;
  
    h2 {
      text-align: center;
    }
  
    form {
      display: flex;
      flex-direction: column;
  
      .form-group {
        margin-bottom: 1rem;
  
        label {
          font-weight: bold;
        }
  
        select,
        input {
          width: 100%;
          padding: 0.5rem;
          border: 1px solid #ccc;
          border-radius: 4px;
        }
      }
  
      button {
        width: 100%;
        padding: 0.5rem;
        background-color: #007bff;
        color: #fff;
        border: none;
        border-radius: 4px;
        cursor: pointer;
  
        &:hover {
          background-color: #0056b3;
        }
      }
    }
  }
  