.carousel {
  position: relative;
  width: 100%;
  height: 100vh; /* Make the carousel take the full viewport height */
  overflow: hidden;

  .carousel-slide {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 1s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &.active {
      opacity: 1;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover; /* Make the image cover the entire slide */
    }

    .caption {
      position: absolute;
      bottom: 10%;
      left: 50%;
      transform: translateX(-50%);
      color: #fff;
      background: rgba(0, 0, 0, 0.5);
      padding: 10px 20px;
      border-radius: 5px;
      font-size: 1.2rem;
      text-align: center;
      max-width: 90%;
    }
  }

  .carousel-indicators {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;

    .indicator {
      width: 10px;
      height: 10px;
      margin: 0 5px;
      background-color: #fff;
      border: none;
      border-radius: 50%;
      cursor: pointer;
      opacity: 0.5;

      &.active {
        opacity: 1;
      }
    }
  }
}
