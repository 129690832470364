.logout-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #f00;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
  
    &:hover {
      background-color: #d00;
    }
}