table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  
    th, td {
      padding: 12px;
      text-align: left;
      border-bottom: 1px solid #ddd;
    }
  
    th {
      background-color: #f4f4f4;
    }
  
    tbody tr:hover {
      background-color: #f1f1f1;
    }
  
    td {
      &.debit {
        color: red;
      }
  
      &.credit {
        color: green;
      }
    }
  }
  
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  
    button {
      margin: 0 5px;
      padding: 8px 16px;
      border: 1px solid #ddd;
      background-color: #f4f4f4;
      cursor: pointer;
  
      &.active {
        background-color: #007bff;
        color: white;
      }
  
      &:hover {
        background-color: #ddd;
      }
    }
  }
  