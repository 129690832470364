$caution-background: #fff3cd;
$caution-border: #ffeeba;
$caution-color: #856404;
$icon-color: #ffc107;

.caution-message {
  display: flex;
  align-items: center;
  padding: 20px;
  border: 1px solid $caution-border;
  border-radius: 8px;
  background-color: $caution-background;
  color: $caution-color;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px 0;

  .caution-icon {
    color: $icon-color;
    margin-right: 20px;
  }

  .message-content {
    .message-title {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 10px;
    }

    .message-text {
      font-size: 16px;
      line-height: 1.5;
    }
  }
}

@media (max-width: 768px) {
  .caution-message {
    flex-direction: column;
    text-align: center;

    .caution-icon {
      margin-bottom: 10px;
    }

    .message-content {
      .message-title {
        font-size: 18px;
      }

      .message-text {
        font-size: 14px;
      }
    }
  }
}

@media (max-width: 480px) {
  .caution-message {
    padding: 15px;

    .caution-icon {
      margin-bottom: 10px;
    }

    .message-content {
      .message-title {
        font-size: 16px;
      }

      .message-text {
        font-size: 12px;
      }
    }
  }
}
