.bank-transfer-form {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
  
    h2 {
      margin-bottom: 20px;
      color: #333;
    }
  
    .input-group {
      margin-bottom: 15px;
      text-align: left;
  
      label {
        display: block;
        font-size: 14px;
        color: #666;
        margin-bottom: 5px;
      }
  
      input,
      select {
        width: 100%;
        padding: 10px;
        font-size: 16px;
        border: 1px solid #ced4da;
        border-radius: 4px;
        outline: none;
        transition: border-color 0.3s;
  
        &:focus {
          border-color: #1c5306;
        }
      }
    }
  
    .submit-button {
      width: 100%;
      padding: 10px;
      font-size: 16px;
      border: none;
      border-radius: 4px;
      background-color: #1c5306;
      color: #fff;
      cursor: pointer;
      transition: background-color 0.3s;
  
      &:hover {
        background-color: darken(#1c5306, 10%);
      }
    }
  }
  
  @media (max-width: 768px) {
    .bank-transfer-form {
      padding: 15px;
  
      .input-group {
        margin-bottom: 10px;
      }
  
      .submit-button {
        padding: 8px;
        font-size: 14px;
      }
    }
  }
  
  @media (max-width: 480px) {
    .bank-transfer-form {
      padding: 10px;
  
      h2 {
        font-size: 18px;
      }
  
      .input-group {
        label {
          font-size: 12px;
        }
  
        input,
        select {
          font-size: 14px;
        }
      }
  
      .submit-button {
        padding: 6px;
        font-size: 12px;
      }
    }
  }
  