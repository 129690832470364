/* Define some global styles for the table */
.beneficiary-table-container {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 8px;
}

.title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 2em;
  color: #333;
}

.beneficiary-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;

  thead {
    background-color: #f4f4f4;

    th {
      padding: 15px;
      text-align: left;
      font-weight: 600;
      color: #333;
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid #ddd;

      &:nth-child(even) {
        background-color: #f9f9f9;
      }

      td {
        padding: 15px;
        color: #555;
      }

      .status-button {
        padding: 8px 12px;
        border: none;
        border-radius: 4px;
        color: #fff;
        background-color: #007bff;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
          background-color: #0056b3;
        }
      }
    }
  }
}

/* Responsive styles */
@media (max-width: 768px) {
  .beneficiary-table-container {
    padding: 10px;
  }

  .beneficiary-table {
    thead {
      display: none; // Hide the table header on small screens
      tr{
          th{
          color: #fff;
        }
      }
      
    }

    tbody {
      tr {
        display: block;
        margin-bottom: 15px;

        td {
          display: block;
          text-align: right;
          padding-left: 50%;
          position: relative;

          &:before {
            content: attr(data-label); // Use data-label to show the header name
            position: absolute;
            left: 0;
            width: 50%;
            padding-left: 15px;
            font-weight: bold;
            text-align: left;
          }
        }
      }
    }
  }
}
