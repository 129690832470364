.info-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #1c5306;
  
    .text-container {
      flex: 1;
      margin-right: 20px;
      
  
      .heading {
        font-size: 28px;
        color: #666;
        margin-bottom: 15px;
      }
  
      .text {
        font-size: 18px;
        color: #666;
        line-height: 1.5;
      }
    }
  
    .image-container {
      flex: 1;
      display: flex;
      justify-content: center;
  
      img {
        max-width: 100%;
        height: auto;
        border-radius: 8px;
      }
    }
  }
  
  @media (max-width: 768px) {
    .info-section {
      flex-direction: column;
      text-align: center;
  
      .text-container {
        margin: 0 0 20px 0;
      }
  
      .image-container {
        img {
          max-width: 90%;
        }
      }
    }
  }
  
  @media (max-width: 480px) {
    .info-section {
      padding: 10px;
  
      .text-container {
        .heading {
          font-size: 24px;
        }
  
        .text {
          font-size: 16px;
        }
      }
  
      .image-container {
        img {
          max-width: 100%;
        }
      }
    }
  }
  