.warning-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; /* Covers the entire viewport */
    background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent black background */
    display: flex; /* Center content vertically and horizontally */
    justify-content: center;
    align-items: center;
    z-index: 999; /* Ensure overlay appears above other elements */
  }
  
  .warning-message {
    /* Style the warning message container */
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
  }