$primary-color: #ff0037;
$secondary-color: #6c757d;
$background-image:  url('../../assets/images/background.jpg');
$text-color: #333;
$input-border-color: #ced4da;

.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: $background-image no-repeat center center fixed;
  background-size: cover;
  position: relative;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .login-container {
    position: relative;
    background: #fff;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
    text-align: center;
    z-index: 1;

    .logo-container {
      margin-bottom: 20px;

      .logo {
        max-width: 150px;
        width: 100%;
      }
    }

    .login-title {
      font-size: 24px;
      color: $text-color;
      margin-bottom: 20px;
    }

    .login-form {
      display: flex;
      flex-direction: column;

      .input-group {
        margin-bottom: 20px;
        text-align: left;

        label {
          display: block;
          font-size: 14px;
          color: $secondary-color;
          margin-bottom: 5px;
        }

        input {
          width: 100%;
          padding: 10px;
          font-size: 16px;
          border: 1px solid $input-border-color;
          border-radius: 4px;
          outline: none;
          transition: border-color 0.3s;

          &:focus {
            border-color: $primary-color;
          }
        }
      }

      .login-button {
        background-color: $primary-color;
        color: #fff;
        padding: 10px;
        font-size: 16px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
          background-color: darken($primary-color, 10%);
        }
      }

      .signup-link {
        margin-top: 20px;
        font-size: 14px;
        color: $secondary-color;

        a {
          color: $primary-color;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .login-page {
    padding: 20px;

    .login-container {
      padding: 30px;
    }
  }
}

@media (max-width: 480px) {
  .login-page {
    padding: 10px;

    .login-container {
      padding: 20px;
    }

    .login-title {
      font-size: 20px;
    }

    .login-form {
      .input-group {
        input {
          font-size: 14px;
        }
      }

      .login-button {
        font-size: 14px;
        padding: 8px;
      }

      .signup-link {
        font-size: 12px;
      }
    }
  }
}
