/* AdminLogin.scss */
.admin-login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f7f7f7;
  
    h1 {
      margin-bottom: 20px;
    }
  
    .admin-login-form {
      display: flex;
      flex-direction: column;
      width: 300px;
      padding: 20px;
      background-color: #fff;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
  
      .form-group {
        margin-bottom: 15px;
  
        label {
          display: block;
          margin-bottom: 5px;
        }
  
        input {
          width: 100%;
          padding: 10px;
          border: 1px solid #ccc;
          border-radius: 4px;
        }
      }
  
      .login-button {
        padding: 10px;
        background-color: #007bff;
        color: #fff;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s ease;
  
        &:hover {
          background-color: #0056b3;
        }
      }
    }
  }
  