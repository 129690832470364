.transaction-table {
    width: 100%;
    border-collapse: collapse;
  
    th, td {
      padding: 10px;
      border: 1px solid #ddd;
      text-align: left;
    }
  
    th {
      background-color: #f4f4f4;
      color: black;
    }
  
    input[type="date"] {
      width: 100%;
      padding: 5px;
      box-sizing: border-box;
    }
  
    button {
      padding: 5px 10px;
      background-color: #e74c3c;
      color: white;
      border: none;
      border-radius: 3px;
      cursor: pointer;
      &:hover {
        background-color: #c0392b;
      }
    }
  }
  