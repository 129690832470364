.carousel {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f0f0;
  }

  .Header {
    display: flex;
    flex-direction: column;
  }
  